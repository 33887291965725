<template>
    
    <Section :options="sectionOptions">

            <form id="profile-picture-form" name="profile-picture-form" class="profile-picture-form mb-0" action="#" method="post" @submit="onFormSubmit">

                <h2>{{labels.title}}</h2>

                <div class="current-picture">
                    <img v-if="currentPicture" :src="getStaticURLFromPath(currentPicture)" :alt="displayName">
                    <img v-else src="@public/img/authentication/profile-blank.jpg" :alt="displayName">
                </div>

                <validation-observer v-slot="{ invalid }" slim>
                    <div class="row">

                        <TextField 
                            v-bind:files.sync="form.pictures"
                            id="picture" 
                            :label="labels.picture" 
                            :placeholder="labels.picture_placeholder" 
                            :rowClass="{'col-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="file"
                            required 
                        />

                        <div class="col-12 form-group">
                            <button :disabled="invalid" class="button button-3d button-black m-0 ladda-button" data-style="zoom-in" id="profile-picture-form-submit" name="profile-picture-form-submit" value="profile-picture" type="submit">{{labels.button}}</button>
                            <button v-if="currentPicture != undefined" class="button button-3d button-black m-0 ladda-button" data-style="zoom-in" id="profile-picture-delete" name="profile-picture-delete" value="profile-picture-delete" type="button" @click="deletePicture">{{labels.deleteButton}}</button>
                        </div>
                    </div>
                </validation-observer>

            </form>

    </Section>

</template>

<style>

.profile-picture-form {
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

.profile-picture-form .current-picture {
    max-height: 200px;
    max-width: 200px;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 10px;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';

/** @cmsOptions */
export interface ProfilePictureOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface ProfilePictureLabels {
    
}

/** @cmsSlots */
export interface ProfilePictureSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<ProfilePictureOptions>,
        labels: {
          type: Object as PropType<ProfilePictureLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<ProfilePictureSlots>
    },
    components: {
        Section,
        TextField
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const user = computed(() => {
            return $store.getters['authentication/' + authenticationTypes.getters.GET_USER];
        });

        const displayName = user.value.profile.displayName;

        const form = {
            pictures:[]
        }

        var laddaSubmit:Ladda.LaddaButton|null = null;
        var laddaDelete:Ladda.LaddaButton|null = null;

        const labels = {
            title: app.$t('authentication.profile-picture.title'),
            picture: app.$t('authentication.register.form.picture'),
            picture_placeholder: app.$t('authentication.register.form.picture_placeholder'),
            button: app.$t('authentication.profile-picture.button'),
            deleteButton: app.$t('authentication.profile-picture.delete-button')
        }

        const currentPicture = computed(() => {
            if(user.value.profile.picture) {
                return user.value.profile.picture.thumbnail.path
            }
            return undefined;
        })

        const computedOptions:ProfilePictureOptions = {
            sectionOptions : {
                
            },
            ...props.options
        };

        const onFormSubmit = (evt:Event) => {
            evt.preventDefault();

            var options:api.ApiVueOptions =  {
                app
            }

            var input = {
                "userID" : user.value._id,
            }

            var formData = new FormData();
            if(form.pictures != null && form.pictures.length > 0) {
                formData.append("picture", form.pictures[0]!, (form.pictures[0]! as File).name);  
            } 

            laddaSubmit!.start();
            
            api.postAPIFormData('/api/auth/profile/update-picture', formData, options, input).then((response:any) => {
                if(response.updated && response.user) {
                    // We update current user
                    $store.commit('authentication/' + authenticationTypes.mutations.UPDATE_USER, response.user);
                    form.pictures = [];
                }

                laddaSubmit!.stop();
            });
        }

        const deletePicture = (evt:Event) => {
            evt.preventDefault();

            var options:api.ApiVueOptions =  {
                app
            }

            var input = {
                "userID" : user.value._id,
            }

            var deleteButton:HTMLButtonElement|null = document.querySelector( 'form[name=profile-picture-form] button.ladda-button[name=profile-picture-delete]' );
            laddaDelete = Ladda.create(deleteButton!);
            laddaDelete!.start();
            
            api.postAPI('/api/auth/profile/delete-picture', input, options).then((response:any) => {
                if(response.deleted && response.user) {
                    // We update current user
                    $store.commit('authentication/' + authenticationTypes.mutations.UPDATE_USER, response.user);
                }

                laddaDelete!.stop();
                laddaDelete!.remove();
            });
        }

        onMounted(() => {
            var button:HTMLButtonElement|null = document.querySelector( 'form[name=profile-picture-form] button.ladda-button[name=profile-picture-form-submit]' );
            laddaSubmit = Ladda.create(button!);
        })

        onBeforeUnmount(() => {
        })

        return {
            sectionOptions:computedOptions.sectionOptions,
            form,
            displayName,
            currentPicture,
            labels,
            onFormSubmit,
            deletePicture
        }

    }
})
</script>